<template>
    <div>
        <mf-box :empty="!quays.length">
            <div slot="header">
                Quay Multiview Timeline
            </div>
            <div class="quay-timeline">
                <div class="quay-timeline__header">
                    <div class="quay-timeline__items">
                        <mf-datepicker
                            class="quay-timeline__datepicker"
                            v-model="highlighted.from"
                            full-month-name
                            calendar-button
                            :disabled-dates="{ from: highlighted.to }"
                            required
                        />
                        <mf-datepicker
                            class="quay-timeline__datepicker"
                            v-model="highlighted.to"
                            full-month-name
                            calendar-button
                            :disabled-dates="{ to: highlighted.from }"
                            required
                        />
                    </div>
                    <div class="quay-timeline__items">
                        <div
                            class="quay-timeline__item"
                            :class="{ 'quay-timeline__item_selected': !expired }"
                            @click="toggleExpired"
                        >
                            <mf-icon class="quay-timeline__icon" icon-name="icon-hide" />
                            <div class="quay-timeline__text">{{ expired ? 'Hiding Expired' : 'Showing Expired' }}</div>
                        </div>
                        <div
                            class="quay-timeline__item"
                            :class="{ 'quay-timeline__item_disabled': selectedScale === MAX_SCALE }"
                            @click="increaseZoom"
                        >
                            <mf-icon class="quay-timeline__icon" icon-name="icon-zoom-in" />
                            <div class="quay-timeline__text">Zoom in</div>
                        </div>
                        <div
                            class="quay-timeline__item"
                            :class="{ 'quay-timeline__item_disabled': selectedScale === MIN_SCALE }"
                            @click="decreaseZoom"
                        >
                            <mf-icon class="quay-timeline__icon" icon-name="icon-zoom-out" />
                            <div class="quay-timeline__text">Zoom out</div>
                        </div>
                    </div>
                </div>
                <mf-divider />
                <mf-responsive-table v-if="(displayedEvents && displayedEvents.length) || (quays && quays.length)">
                    <div class="quay-timeline__table">
                        <div class="quay-timeline__past-dates" :style="pastDatesContainerStyle" />
                        <time-labels
                            :number-of-time-labels="numberOfTimeLabels"
                            :start-date="Date.parse(highlighted.from)"
                            :time-coefficient="timeCoefficient"
                        />
                        <mf-divider />
                        <template v-if="displayedEvents && displayedEvents.length">
                            <template v-for="(quay, index) in quays">
                                <div
                                    class="quay-timeline__line"
                                    :key="quay.URN"
                                    :class="index % 2 && 'quay-timeline__line_light'"
                                    :style="lineStyle"
                                >
                                    <div class="quay-timeline__title">
                                        {{ quay.convertedName }}
                                    </div>
                                    <div class="quay-timeline__event-lines" :key="`lines-${quay.URN}`">
                                        <div
                                            class="quay-timeline__event-line"
                                            v-for="(eventLine, index) in eventLines[quay.URN]"
                                            :key="`${quay.URN}-line-${index}`"
                                        >
                                            <event
                                                v-for="event in eventLine"
                                                :key="event.id"
                                                :event="event"
                                                @toggleModal="toggleModal"
                                            >
                                                {{ event.vessel.name }}
                                            </event>
                                        </div>
                                    </div>
                                </div>
                                <mf-divider :key="`divider-${quay.URN}`" :style="lineStyle" />
                            </template>
                        </template>
                        <template v-else-if="events && !events.length">
                            <template v-for="quay in quays">
                                <div class="quay-timeline__title" :key="quay.URN">
                                    {{ quay.convertedName }}
                                </div>
                                <mf-divider :key="`divider-${quay.URN}`" :style="lineStyle" />
                            </template>
                        </template>
                    </div>
                    <div
                        v-if="(displayedEvents && !displayedEvents.length) || (events && !events.length)"
                        class="quay-timeline__empty"
                    >
                        {{ emptyText }}
                    </div>
                </mf-responsive-table>
            </div>
            <div slot="empty-text">
                <div>You don't have any favourite quays</div>
                <div>Quay Multiview Timeline is used to get an overview</div>
            </div>
        </mf-box>
        <event-modal v-if="selectedEvent" :event="selectedEvent" :is-open="isModalShown" @close="toggleModal" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { debounce } from 'lodash';
    import TimeLabels from '../timeline-components/TimeLabels';
    import Event from '../timeline-components/Event';
    import EventModal from '@/modals/EventModal';
    import * as TIMELINE_CONSTANTS from '../../config/timelineConstants';
    import { getQuayFromStore } from '@/helpers/getQuayFromState.helper';

    const WIDTH_FOR_VESSELS_NAMES_BLOCK = 200;

    export default {
        name: 'QuayMultiviewTimeline',
        components: { Event, EventModal, TimeLabels },
        mounted() {
            this.fetchData();
            this.favouriteQuays.forEach(async quayId => {
                const quay = await this.getQuayFromStore(quayId);
                this.quays.push(quay);
            });
        },
        computed: {
            ...mapGetters({ favouriteQuays: 'favouriteQuays', events: 'quaysEvents' }),
            eventLines() {
                return (
                    this.eventsWithWidthInfo &&
                    this.eventsWithWidthInfo.reduce((result, value) => {
                        if (!result[value.at.URN]) {
                            result[value.at.URN] = [[value]];
                        } else {
                            const position = this.findLineOnWhichEventCanBeDisplayed(value, result[value.at.URN]);
                            if (position > -1) {
                                result[value.at.URN][position].push(value);
                            } else {
                                const position = result[value.at.URN].length;
                                result[value.at.URN][position] = [value];
                            }
                        }
                        return result;
                    }, {})
                );
            },
            eventsWithWidthInfo() {
                return (
                    this.displayedEvents &&
                    this.displayedEvents.map(event => {
                        let divWidth =
                            ((event.endTime - event.startTime) / this.timeCoefficient) *
                                TIMELINE_CONSTANTS.WIDTH_OF_PERIOD || 1;
                        let divMovement =
                            ((event.startTime - this.highlighted.from) / this.timeCoefficient) *
                            TIMELINE_CONSTANTS.WIDTH_OF_PERIOD;
                        event.style = {
                            left: `${divMovement + WIDTH_FOR_VESSELS_NAMES_BLOCK}px`,
                            width: `${divWidth}px`,
                        };
                        event.showTime = divWidth > 120;
                        return event;
                    })
                );
            },
            displayedEvents() {
                return this.events && this.events.filter(event => event.isExpired === !this.expired);
            },
            numberOfTimeLabels() {
                const firstLabel = Math.floor(this.highlighted.from);
                const lastLabel = Math.ceil(this.highlighted.to);

                return Math.floor((lastLabel - firstLabel) / this.timeCoefficient) + 1;
            },
            timeCoefficient() {
                return (
                    TIMELINE_CONSTANTS.MILLISECONDS_IN_DAY_HALF / TIMELINE_CONSTANTS.SCALE_NUMBERS[this.selectedScale]
                );
            },
            lineStyle() {
                return {
                    width: `${WIDTH_FOR_VESSELS_NAMES_BLOCK +
                        (this.numberOfTimeLabels + 1) * TIMELINE_CONSTANTS.WIDTH_OF_PERIOD}px`,
                };
            },
            pastDatesContainerStyle() {
                const currentDate = Date.parse(new Date());
                let divWidth = 0;
                if (currentDate > this.highlighted.to) {
                    divWidth = (this.numberOfTimeLabels + 1) * TIMELINE_CONSTANTS.WIDTH_OF_PERIOD;
                } else if (currentDate > this.highlighted.from) {
                    divWidth =
                        ((currentDate - this.highlighted.from) / this.timeCoefficient) *
                        TIMELINE_CONSTANTS.WIDTH_OF_PERIOD;
                }
                return {
                    left: `${WIDTH_FOR_VESSELS_NAMES_BLOCK}px`,
                    width: `${divWidth}px`,
                };
            },
            emptyText() {
                return this.events && this.events.length
                    ? 'All visits for this time span have expired'
                    : 'No visits between selected dates';
            },
        },
        watch: {
            'highlighted.from'(val) {
                val && this.fetchData();
            },
            'highlighted.to'(val) {
                val && this.fetchData();
            },
        },
        data() {
            const now = new Date();
            return {
                selectedScale: TIMELINE_CONSTANTS.MIN_SCALE,
                expired: true,
                isAbleToScroll: false,
                highlighted: {
                    from: this.startOfTheMonth(now),
                    to: this.endOfTheMonth(now),
                },
                dates: [],
                startX: 0,
                MIN_SCALE: TIMELINE_CONSTANTS.MIN_SCALE,
                MAX_SCALE: TIMELINE_CONSTANTS.MAX_SCALE,
                quays: [],
                selectedEvent: {},
                isModalShown: false,
            };
        },
        methods: {
            getQuayFromStore,
            fetchData: debounce(async function() {
                const params = {
                    from_time: this.highlighted.from.toISOString(),
                    to_time: this.highlighted.to.toISOString(),
                    location: this.favouriteQuays,
                    event_definition: 'VESSEL_AT_BERTH',
                };
                await this.$store.dispatch('getSelectedQuaysEvents', params);
            }, 500),
            increaseZoom() {
                this.selectedScale += 1;
            },
            decreaseZoom() {
                this.selectedScale -= 1;
            },
            toggleExpired() {
                this.expired = !this.expired;
            },
            findLineOnWhichEventCanBeDisplayed(event, existingLines) {
                return existingLines.findIndex(lineEvents => this.isEventCanBeDisplayedOnSameLine(event, lineEvents));
            },
            isEventCanBeDisplayedOnSameLine(event, existingEvents) {
                return (
                    event.startTime > Math.max(...existingEvents.map(existingEvent => existingEvent.endTime)) ||
                    event.endTime < Math.min(...existingEvents.map(existingEvent => existingEvent.startTime))
                );
            },
            startOfTheMonth(date) {
                return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
            },
            endOfTheMonth(date) {
                return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
            },
            addMonths(date, months) {
                date = new Date(date);
                const oldDate = date.getDate();
                date.setMonth(date.getMonth() + months, 1);
                const newMonth = date.getMonth();
                date.setDate(oldDate);
                if (date.getMonth() !== newMonth) {
                    date.setDate(0);
                }
                return date;
            },
            removeDays(date, days) {
                date = new Date(date);
                return date.setDate(date.getDate() - days);
            },
            toggleModal(event) {
                if (event) {
                    this.selectedEvent = event;
                    this.isModalShown = true;
                } else {
                    this.isModalShown = false;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .quay-timeline__header {
        margin-bottom: $space-20;
        display: flex;
        justify-content: space-between;
    }

    .quay-timeline__event-lines {
        display: flex;
        flex-direction: column;
    }

    .quay-timeline__event-line {
        height: 48px;
    }

    .quay-timeline__line_light {
        background: $marine-600;
    }

    .quay-timeline__items {
        display: flex;
        align-items: center;
    }

    .quay-timeline__datepicker {
        &:first-child {
            margin-right: $space-20;
        }
    }

    .quay-timeline__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $white;
        font-size: $font-12;
        line-height: $font-14;
        cursor: pointer;
        text-align: center;
        &:hover {
            opacity: 0.8;
        }
        &:not(:first-child) {
            margin-left: $space-16;
        }
    }

    .quay-timeline__item_selected {
        color: $blue-300;
    }

    .quay-timeline__item_disabled {
        pointer-events: none;
        opacity: 0.3;
    }

    .quay-timeline__icon {
        margin-bottom: $space-8;
    }

    .quay-timeline__table {
        margin-top: $space-20;
        padding-bottom: $space-4;
        position: relative;
        .time-labels {
            margin-left: 200px;
        }
    }

    .quay-timeline__title {
        font-weight: bold;
        font-size: $font-13;
        line-height: $font-18;
        display: flex;
        align-items: center;
        color: $white;
        opacity: 0.8;
        max-width: 200px;

        padding: $space-8;
    }

    .quay-timeline__past-dates {
        position: absolute;
        height: 100%;
        z-index: 1;
        background: $marine-800;
        opacity: 0.7;
    }

    .quay-timeline__line {
        display: flex;
        position: relative;
    }

    .quay-timeline__empty {
        margin-top: $space-50;
        text-align: center;
        font-size: $font-16;
        line-height: $font-20;
        color: $marine-400;
        min-height: 50px;
    }
</style>
